<template>
    <validation-observer tag="div" ref="observer" v-if="!loading">
        <b-row>
            <b-col cols="4">
                <zw-input-group v-model="form.onedrive_client_id"
                                name="client_id"
                                :label-prefix="labelPrefix"
                ></zw-input-group>
            </b-col>
            <b-col cols="4">
                <zw-input-group v-model="form.onedrive_client_secret"
                                name="client_secret"
                                :label-prefix="labelPrefix"
                ></zw-input-group>
            </b-col>
            <b-col cols="4">
                <zw-input-group v-model="form.onedrive_state"
                                name="onedrive_state"
                                :readonly="true"
                                :label-prefix="labelPrefix"
                ></zw-input-group>
            </b-col>
        </b-row>

        <b-row v-if="url">
            <b-col cols="12">
                <b-button variant="warning"
                          target="_blank"
                          :href="url">
                    {{ $t('common.button.authorize') }}
                </b-button>
            </b-col>
        </b-row>

        <b-row>
            <b-col sm="12" class="text-xs-center mt-2">
                <b-row align-h="end">
                    <b-col sm="6" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>

        </b-row>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'SettingsOnedrive',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            form: {},
            url: null,
            labelPrefix: 'settings.onedrive.label.'
        }
    },
    methods: {
        ...mapGetters('Settings', ['getOnedrive']),
        shown() {
            this.loading = true
            const settingsOneDrive = this.$store.dispatch('Settings/fetchOnedrive')

            Promise.all([settingsOneDrive]).then(() => {
                this.form = this.getOnedrive()

                this.loading = false
            });
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    window.axios.post(window.apiUrl + '/settings/onedrive', this.form, {
                        'skip_loading': true,
                    })
                        .then(response => {
                            this.afterSave(response)
                            this.url = response.data.url
                        })
                        .catch((error) => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        afterSave(response) {
            if (response.status == 200 || response.status == 201) {
                this.$root.showMessage(
                    this.$t('common.form.title.success'),
                    this.$t('common.toasts.success_updated'),
                    'success'
                )

                this.shown()
            }
            this.$root.afterSettingsUpdate()
        },
    },
    mounted() {
        this.shown()
    }
}
</script>